import React from "react";
import {appendScript} from './utils/appendScript'
import {removeScript} from './utils/removeScript'

class Grids extends React.Component {
    render() {
  
        return (
        <div className="Grids">
            <title>Generate Grid Art - David Pearson Art</title>
            <canvas id="canvas2"></canvas>
        </div>
        );
        }

    componentDidMount() {
        removeScript("/genart/grids.js");
        removeScript("/genart/psypaint.js");
        appendScript("/genart/grids.js");
        }
    
    componentDidUnmount() {
        removeScript("/genart/grids.js");
        }
}

export default Grids;