import React from "react";
/*import { Helmet } from "react-helmet"*/
/*import { Fragment } from 'react';*/
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Portfolio from './Portfolio';
import Nav from './Nav';
import Footer from './Footer';
import Home from './Home';
import About from "./About";
import Art from "./art";
import Aromaswild from "./aromaswild";
import Authreqd from "./authreqd";
import Badrequest from "./badrequest";
import Codeart from "./codeart";
import Dpgames from "./dpgames";
import Exhibitions from "./exhibitions";
import Forbid from "./forbid";
import Games from "./games";
import Jesport from "./jesport";
import Notfound from "./notfound";
import Privacy from "./privacy";
import Psypaint from "./psypaint";
import Grids from "./grids";
import Python from "./python";
import Skycity from "./skycity";
import Terms from "./terms";
  
function App() {
  return (
    <div className="App">
      <head>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link rel="icon" type="image/x-icon" href="pics/favicon.ico"/>
          <link rel="shortcut icon" type="image/jpg" href="pics/favicon.jpg"/>
      </head>
      <Router>
        <Nav />
        <div className="container">
            <Routes>
                <Route path="/" element={<Portfolio />} />
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/codeart" element={<Codeart />} />
                <Route path="/art" element={<Art />} />
                <Route path="/aromaswild" element={<Aromaswild />} />
                <Route path="/authreqd" element={<Authreqd />} />
                <Route path="/badrequest" element={<Badrequest />} />
                <Route path="/dpgames" element={<Dpgames />} />
                <Route path="/exhibitions" element={<Exhibitions />} />
                <Route path="/forbid" element={<Forbid />} />
                <Route path="/games" element={<Games />} />
                <Route path="/jesport" element={<Jesport />} />
                <Route path="/notfound" element={<Notfound />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/codeart/psypaint" element={<Psypaint/>} />
                <Route path="/codeart/grids" element={<Grids/>} />
                <Route path="/python" element={<Python />} />
                <Route path="/skycity" element={<Skycity />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/authreqd" element={<Authreqd />} />
                <Route path="/badrequest" element={<Badrequest />} />
                <Route path="/forbid" element={<Forbid />} />
                <Route path="/notfound" element={<Notfound />} />
            </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
}
  
export default App;