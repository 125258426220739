function About() {
  return (
    <div className="about">
        <title>About - David Pearson Art</title>

        <h2 className="pgtitle">About Me</h2>

        <p className="pgtext">I am a versatile web and graphic designer located in the picturesque region of Cumbria, UK. With a robust skill set in HTML and CSS coding, coupled with a proficient understanding of JavaScript and PHP, I bring creativity and technical finesse to every project. Additionally, I am well-versed in utilizing popular tools like WordPress and the Adobe Creative Suite to craft captivating digital experiences. <br></br><br></br> My portfolio showcases a diverse array of responsive websites meticulously crafted using HTML, JavaScript, and CSS. Several of these projects leverage the power and flexibility of WordPress, often enhanced with tools like Elementor, to deliver dynamic and engaging user experiences.</p>

        <div className="spacer-black"></div>
    </div>
  );
}

export default About;