import { Link } from "react-router-dom";

function Nav() {
  return (
    <div className="Nav">
        <header>
            <nav className="menubar"> 
                <ul>
                    <li className="mmtitle"><Link to="/">DAVID PEARSON ART</Link></li>
                    <li className="mmitem"><Link to="/">Portfolio</Link></li>
                    <li className="mmitem"><Link to="about">About</Link>
                    </li>
                </ul>
            </nav>
        </header>
    </div>
  );
}

export default Nav;
