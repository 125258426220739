import React from "react";
import {appendScript} from './utils/appendScript'
import {removeScript} from './utils/removeScript'

class Psypaint extends React.Component {
    render() {
  
        return (
        <div className="Psypaint">
            <title>Painting - David Pearson Art</title>
            <canvas id="canvas1"></canvas>
        </div>
        );
        }

    componentDidMount() {
        removeScript("/genart/grids.js");
        removeScript("/genart/psypaint.js");
        appendScript("/genart/psypaint.js");
        }
    
    componentDidUnmount() {
        removeScript("/genart/psypaint.js");
        }
}

export default Psypaint;