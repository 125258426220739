function Python() {
    return (
    <div className="Python">
        <title>Python Generative Art - David Pearson Art</title>
        <h1 className="pgtitle">Python Generative Art</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/python-squares.webp" type="image/webp"/>
                <source srcSet="/portfolio/python-squares.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/python-squares.webp" alt="squares and triangles"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">I have created many generative art pieces using Python and JavaScript.</p>
                <br/>
            </div>
        </div>
    </div>
    );
}

export default Python;