import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
        <div className="snippet">
            <p className="blurb">Are you looking for a designer to help design your website, create a logo or create some posters for your business. I’m here to help.</p>
            <div className="socials">
                <a href="mailto:djpearson27@gmail.com?subject=Message%20From%20davidpearsonart.com" target="_blank">
                    <img className="social-icons" src="./email.svg" alt="email"/>
                </a>
                <a href="https://twitter.com/davidpearsonart" target="_blank">
                    <img className="social-icons" src="./twitter.svg" alt="twitter"/>
                </a>
                <a href="https://github.com/david6768" target="_blank">
                    <img className="social-icons" src="./github.svg" alt="github"/>
                </a>
                <a href="https://www.linkedin.com/in/david-pearson1/" target="_blank">
                    <img className="social-icons" src="./linkedin.svg" alt="linkedin"/>
                </a>
                <a href="" target="_blank">
                    <img className="social-icons" src="./deviantart.svg" alt="deviantart"/>
                </a>
            </div>
        </div>
        <nav className="footermenu">  
            <ul>
                <li><Link to="privacy">Privacy Policy</Link>
                </li>
                <li><a href="/sitemap.xml">Sitemap</a>
                </li>
                <li><Link to="about">About My Work</Link>
                </li>
            </ul>        
        </nav>
        <div className="copyright">
            <p className="copyr">
                <a className="madeby" href="https://davidpearsonart.com">Copyright © 2022 David Pearson Art</a>
            </p>
        </div>
    </footer>
  );
}

export default Footer;