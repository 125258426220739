function Games() {
    return (
    <div className="Games">
        <title>Android and Desktop Games - David Pearson Art</title>
        <h1 className="pgtitle">Android and Desktop Games</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/zomble-screenshot.webp" type="image/webp"/>
                <source srcSet="/portfolio/zomble-screenshot.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/zomble-screenshot.webp" alt="zomble screenshot"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">I create games for Android, Windows Mac and Linux.</p>
                <br/>
                <a className="link-text" href="https://play.google.com/store/apps/developer?id=dp-games" target="_blank">play.google.com/store/apps/developer?id=dp-games</a>

            </div>
        </div>
    </div>
    );
}

export default Games;