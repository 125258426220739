import { Link } from "react-router-dom";

function Authreqd() {
    return (
        <div className="Authreqd">
            <title>Error - David Pearson Art</title>
            <Link to="/">
                <div className="error">

                    <h2 className="errortitle">401</h2>

                    <img className="error_img" src="/401.svg" alt="401"/>

                    <div className="spacer"/>
                </div>
            </Link>
        </div>
    );
}

export default Authreqd;
