function Exhibitions() {
    return (
    <div className="Exhibitions">
        <title>Exhibition Poster Designs - David Pearson Art</title>
        <h1 className="pgtitle">Exhibition Poster Designs</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/Alc-mockup.webp" type="image/webp"/>
                <source srcSet="/portfolio/Alc-mockup.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/Alc-mockup.webp" alt="Alchemy Exhibition Poster"/>
            </picture>
            <picture>
                <source srcSet="/portfolio/nq-mockup.webp" type="image/webp"/>
                <source srcSet="/portfolio/nq-mockup.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/nq-mockup.webp" alt="Nortern Quarter Exhibition Poster"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">During my time at University I was involved in many Exhibitions, here are some of the posters I created to promote these Exhibitions.</p>
                <br/>
                <a className="link-text" href="https://dpunitxgroup17nq.blogspot.com/" target="_blank">dpunitxgroup17nq.blogspot.com</a>

            </div>
        </div>
    </div>
    );
}

export default Exhibitions;