function Jesport() {
    return (
    <div className="Jesport">
        <title>J.Easton Sports Coaching Logo Design - David Pearson Art</title>
        <h1 className="pgtitle">J.Easton Sports Coaching Logo Design</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/jeaston-logo.webp" type="image/webp"/>
                <source srcSet="/portfolio/jeaston-logo.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/jeaston-logo.webp" alt="logo screenshot"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">J.Easton Sports Coaching is a local company I created a logo design for.</p>
                <br/>

            </div>
        </div>
    </div>
    );
}

export default Jesport;