import { Link } from "react-router-dom";

function Badrequest() {
    return (
        <div className="Badrequest">
            <title>Error - David Pearson Art</title>
            <Link to="/">
                <div className="error">

                    <h2 className="errortitle">400</h2>

                    <img className="error_img" src="/400.svg" alt="400"/>

                    <div className="spacer"/>
                </div>
            </Link>
        </div>
    );
}

export default Badrequest;
    