import { Link } from "react-router-dom";

function Notfound() {
    return (
        <div className="Notfound">
            <title>Error - David Pearson Art</title>
            <Link to="/">
                <div className="error">

                    <h2 className="errortitle">404</h2>

                    <img className="error_img" src="/404.svg" alt="404"/>

                    <div className="spacer"/>
                </div>
            </Link>
        </div>
    );
}

export default Notfound;
    