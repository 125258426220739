function Aromaswild() {
    return (
    <div className="Aromaswild">
        <title>Aromas Wild - David Pearson Art</title>
        <h1 className="pgtitle">Aromas Wild</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/aromaswild-screenshot.webp" type="image/webp"/>
                <source srcSet="/portfolio/aromaswild-screenshot.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/aromaswild-screenshot.webp" alt="dp-games screenshot"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">Aromaswild is a site I manage and create content for. I have also done some digital and social media marketing for the site, it uses WordPress.</p>
                <br/>
                <a className="link-text" href="https://aromaswild.com" target="_blank">aromaswild.com</a>

            </div>
        </div>
    </div>
    );
}

export default Aromaswild;