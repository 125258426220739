function Dpgames() {
    return (
    <div className="Dpgames">
        <title>DP Games - David Pearson Art</title>
        <h1 className="pgtitle">DP Games</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/dp-games-screen.webp" type="image/webp"/>
                <source srcSet="/portfolio/dp-games-screen.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/dp-games-screen.webp" alt="dp-games screenshot"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">DP Games is a website I created for a collection of Android games I developed, the site currently runs on WordPress and uses Elementor.</p>
                <br/>
                <a className="link-text" href="https://dp-playgames.com" target="_blank">dp-playgames.com</a>

            </div>
        </div>
    </div>
    );
}

export default Dpgames;
    