import React from "react";
import {removeScript} from './utils/removeScript'

function Home() {
  removeScript("/genart/grids.js");
  removeScript("/genart/psypaint.js");
  return (
    <div className="getintouch">
            <h2 className="getintouch-text">Get In Touch.</h2>
            <a href="mailto:djpearson27@gmail.com?subject=Message%20From%20davidpearsonart.com"><button className="sendmessage">Send Me A Message</button></a>
    </div>
  );
}

export default Home;