function Skycity() {
    return (
    <div className="Skycity">
        <title>Sky City Chinese Takeaway - David Pearson Art</title>
        <h1 className="pgtitle">Sky City Chinese Takeaway</h1>
        <div id="feature" className="feature">
            <picture>
                <source srcSet="/portfolio/sc-screen.webp" type="image/webp"/>
                <source srcSet="/portfolio/sc-screen.jpg" type="image/jpeg"/>
                <img className="feature-img" src="/portfolio/sc-screen.webp" alt="sky city screenshot"/>
            </picture>
            <div className="feature-txtbox">
                <p className="feature-text">Sky City is a takeaway business I created a website for. The site uses PHP, HTML and CSS.</p>
                <br/>
                <a className="link-text" href="https://skycitychinese.shop" target="_blank">skycitychinese.shop</a>

            </div>
        </div>
    </div>
    );
}

export default Skycity;
    