import { Link } from "react-router-dom";
import React from "react";
import {removeScript} from './utils/removeScript'

function Codeart() {
    removeScript("/genart/grids.js");
    removeScript("/genart/psypaint.js");
    return (
        <div className="Codeart">
            <title>Javascript Art - David Pearson Art</title>
            <div className="home">
                <div className="portfolio">
                    <div className="row">
                        <div className="column"><Link to="psypaint">
                            <picture className="portfolio-img">
                                <source srcSet="/genart/psy.webp" type="image/webp"/>
                                <source srcSet="/genart/psy.jpg" type="image/jpeg"/>
                                <img  className="portfolio-img" src="/genart/psy.webp" alt="Psychedellic Patterns"/>
                            </picture>
                            <picture className="overlay-img">
                                <source srcSet="/genart/psy1.webp" type="image/webp"/>
                                <source srcSet="/genart/psy1.jpg" type="image/jpeg"/>
                                <img  className="overlay-img" src="/genart/psy1.webp" alt="Psychedellic Patterns"/>
                            </picture>
                            <div className="overlay">
                                <div className="text"><span className="highlight">Draw Psychedellic Patterns</span></div>
                            </div>
                        </Link></div>
                        <div className="column"><Link to="grids" className="portfolio-link">
                            <picture className="portfolio-img">
                                <source srcSet="/genart/grids.webp" type="image/webp"/>
                                <source srcSet="/genart/grids.jpg" type="image/jpeg"/>
                                <img className="portfolio-img" src="/genart/grids.webp" alt="aromaswild-logo"/>
                            </picture>
                            <picture className="overlay-img">
                                <source srcSet="/genart/grids1.webp" type="image/webp"/>
                                <source srcSet="/genart/grids1.jpg" type="image/jpeg"/>
                                <img  className="overlay-img" src="/genart/grids1.webp" alt="essential oils"/>
                            </picture>
                            <div className="overlay">
                                <div className="text"><span className="highlight">Grid Generator</span></div>
                            </div>
                        </Link></div>
                        <div className="column"><a className="portfolio-link" href="skycity.php">
                            <picture className="portfolio-img">
                                <source srcSet="/genart/skycity.webp" type="image/webp"/>
                                <source srcSet="/genart/skycity.jpg" type="image/jpeg"/>
                                <img className="portfolio-img" src="/genart/skycity.webp" alt="Restaurant Website"/>
                            </picture>
                            <picture className="overlay-img">
                                <source srcSet="/genart/skycity-mockup.webp" type="image/webp"/>
                                <source srcSet="/genart/skycity-mockup.jpg" type="image/jpeg"/>
                                <img  className="overlay-img" src="/genart/skycity-mockup.webp" alt="Restaurant"/>
                            </picture>
                            <div className="overlay">
                                <div className="text"><span className="highlight"></span></div>
                            </div>
                        </a></div>
                    </div>
                </div>
                <div className="getintouch">
                    <h2 className="getintouch-text">Get In Touch.</h2>
                    <a href="mailto:djpearson27@gmail.com?subject=Message%20From%20davidpearsonart.com"><button className="sendmessage">Send Me A Message</button></a>
                </div>
            </div>
        </div>
    );
}

export default Codeart;
    