import { Link } from "react-router-dom";

function Forbid() {
    return (
        <div className="Forbid">
            <title>Error - David Pearson Art</title>
            <Link to="/">
                <div className="error">

                    <h2 className="errortitle">403</h2>

                    <img className="error_img" src="/403.svg" alt="403"/>

                    <div className="spacer"/>
                </div>
            </Link>
        </div>
    );
}

export default Forbid;