import { Link } from "react-router-dom";
import React from "react";
import {removeScript} from './utils/removeScript'

function Portfolio() {
    removeScript("/genart/grids.js");
    removeScript("/genart/psypaint.js");
  return (
    <div className="Portfolio">
        <title>Portfolio - David Pearson Art</title>
      <div className="portfolio">
        <div className="row">
          <div className="column"><Link to="dpgames" className="portfolio-link">
            <picture className="portfolio-img">
              <source srcSet="/portfolio/dpgames-logo.webp" type="image/webp"></source>
              <source srcSet="/portfolio/dpgames-logo.jpg" type="image/jpeg"></source>
              <img  className="portfolio-img" src="/portfolio/dpgames-logo.webp" alt="dpgames-logo"></img>
            </picture>
            <picture className="overlay-img">
                <source srcSet="/portfolio/dp-games.webp" type="image/webp"></source>
                <source srcSet="/portfolio/dp-games.jpg" type="image/jpeg"></source>
                <img  className="overlay-img" src="/portfolio/dp-games.webp" alt="dpgames"></img>
            </picture>
            <div className="overlay">
                        <div className="text"><span className="highlight">DP-Games Website</span></div>
                    </div>
          </Link></div>
                <div className="column"><Link to="aromaswild" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/aromaswild-log.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/aromaswild-log.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/aromaswild-log.webp" alt="aromaswild-logo"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/aromas-mockup.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/aromas-mockup.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/aromas-mockup.webp" alt="essential oils"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Aromas Wild Website</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="skycity" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/skycity.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/skycity.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/skycity.webp" alt="Restaurant Website"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/skycity-mockup.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/skycity-mockup.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/skycity-mockup.webp" alt="Restaurant"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Sky City Chinese Takeaway Website</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="exhibitions" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/nq-mockup.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/nq-mockup.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/nq-mockup.webp" alt="black exhibition poster"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/Alc-mockup.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/Alc-mockup.jpg" type="image/jpeg"></source>
                        <img className="overlay-img" src="/portfolio/Alc-mockup.webp" alt="blue exhibition poster"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Exhibition Posters</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="jesport" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/jeaston-logo.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/jeaston-logo.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/jeaston-logo.webp" alt="j.easton.logo"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/j-easton2.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/j-easton2.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/j-easton2.webp" alt="logo on ball"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">J.Easton Sports Coaching Logo Design</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="games" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/zomble-mockup.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/zomble-mockup.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/zomble-mockup.webp" alt="zombie game"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/space-game.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/space-game.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/space-game.webp" alt="space game"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Android Games Development</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="art" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/painting-mockup.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/painting-mockup.jpg" type="image/jpeg"></source>
                        <img  className="portfolio-img" src="/portfolio/painting-mockup.webp" alt="painting"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/abstract-painting.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/abstract-painting.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/abstract-painting.webp" alt="abstract painting"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Paintings</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="art" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/bridge-square.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/bridge-square.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/bridge-square.webp" alt="bridge-square"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/abstr-draw.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/abstr-draw.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/abstr-draw.webp" alt="abstract drawing"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Drawings</span></div>
                    </div>
                </Link></div>
                <div className="column"><Link to="codeart" className="portfolio-link">
                    <picture className="portfolio-img">
                        <source srcSet="/portfolio/python-squares.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/python-squares.jpg" type="image/jpeg"></source>
                        <img className="portfolio-img" src="/portfolio/python-squares.webp" alt="python-squares"></img>
                    </picture>
                    <picture className="overlay-img">
                        <source srcSet="/portfolio/python-shapes.webp" type="image/webp"></source>
                        <source srcSet="/portfolio/python-shapes.jpg" type="image/jpeg"></source>
                        <img  className="overlay-img" src="/portfolio/python-shapes.webp" alt="python shapes"></img>
                    </picture>
                    <div className="overlay">
                        <div className="text"><span className="highlight">Generative Art Using Python And JavaScript</span></div>
                    </div>
                </Link></div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
