function Art() {
    return (
    <div className="Art">
        <title>Art Gallery - David Pearson Art</title>
        <div className="art">
            <div className="gallery">
                <div className="art-row">
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/001.webp" type="image/webp"/><source srcSet="/art/001.jpg" type="image/jpeg"/><img className="gallery-img" src="/art/00a1.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/002.webp" type="image/webp"/><source srcSet="/art/002.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/002.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/003.webp" type="image/webp"/><source srcSet="/art/003.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/003.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/004.webp" type="image/webp"/><source srcSet="/art/004.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/004.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/005.webp" type="image/webp"/><source srcSet="/art/005.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/005.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/005a.webp" type="image/webp"/><source srcSet="/art/005a.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/005a.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/006.webp" type="image/webp"/><source srcSet="/art/006.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/006.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/007.webp" type="image/webp"/><source srcSet="/art/007.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/007.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/008.webp" type="image/webp"/><source srcSet="/art/008.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/008.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/009.webp" type="image/webp"/><source srcSet="/art/009.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/009.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/010.webp" type="image/webp"/><source srcSet="/art/010.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/010.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/011.webp" type="image/webp"/><source srcSet="/art/011.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/011.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/012.webp" type="image/webp"/><source srcSet="/art/012.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/012.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/013.webp" type="image/webp"/><source srcSet="/art/013.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/013.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/014.webp" type="image/webp"/><source srcSet="/art/014.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/014.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/015.webp" type="image/webp"/><source srcSet="/art/015.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/015.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/016.webp" type="image/webp"/><source srcSet="/art/016.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/016.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/017.webp" type="image/webp"/><source srcSet="/art/017.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/017.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/018.webp" type="image/webp"/><source srcSet="/art/018.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/018.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/019.webp" type="image/webp"/><source srcSet="/art/019.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/019.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/020.webp" type="image/webp"/><source srcSet="/art/020.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/020.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/021.webp" type="image/webp"/><source srcSet="/art/021.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/021.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/022.webp" type="image/webp"/><source srcSet="/art/022.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/022.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/023.webp" type="image/webp"/><source srcSet="/art/023.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/023.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/024.webp" type="image/webp"/><source srcSet="/art/024.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/024.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/025.webp" type="image/webp"/><source srcSet="/art/025.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/025.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/026.webp" type="image/webp"/><source srcSet="/art/026.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/026.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/027.webp" type="image/webp"/><source srcSet="/art/027.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/027.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/028.webp" type="image/webp"/><source srcSet="/art/028.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/028.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/029.webp" type="image/webp"/><source srcSet="/art/029.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/029.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/030.webp" type="image/webp"/><source srcSet="/art/030.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/030.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/031.webp" type="image/webp"/><source srcSet="/art/031.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/031.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/032.webp" type="image/webp"/><source srcSet="/art/032.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/032.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/033.webp" type="image/webp"/><source srcSet="/art/033.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/033.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/034.webp" type="image/webp"/><source srcSet="/art/034.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/034.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/035.webp" type="image/webp"/><source srcSet="/art/035.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/035.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/036.webp" type="image/webp"/><source srcSet="/art/036.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/036.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/037.webp" type="image/webp"/><source srcSet="/art/037.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/037.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/038.webp" type="image/webp"/><source srcSet="/art/038.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/038.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/039.webp" type="image/webp"/><source srcSet="/art/039.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/039.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/040.webp" type="image/webp"/><source srcSet="/art/040.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/040.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/041.webp" type="image/webp"/><source srcSet="/art/041.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/041.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/042.webp" type="image/webp"/><source srcSet="/art/042.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/042.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/043.webp" type="image/webp"/><source srcSet="/art/043.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/043.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/044.webp" type="image/webp"/><source srcSet="/art/044.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/044.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/045.webp" type="image/webp"/><source srcSet="/art/045.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/045.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/046.webp" type="image/webp"/><source srcSet="/art/046.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/046.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/047.webp" type="image/webp"/><source srcSet="/art/047.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/047.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/048.webp" type="image/webp"/><source srcSet="/art/048.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/048.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/049.webp" type="image/webp"/><source srcSet="/art/049.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/049.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/050.webp" type="image/webp"/><source srcSet="/art/050.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/050.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/051.webp" type="image/webp"/><source srcSet="/art/051.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/051.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/052.webp" type="image/webp"/><source srcSet="/art/052.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/052.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/053.webp" type="image/webp"/><source srcSet="/art/053.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/053.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/054.webp" type="image/webp"/><source srcSet="/art/054.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/054.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/055.webp" type="image/webp"/><source srcSet="/art/055.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/055.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/056.webp" type="image/webp"/><source srcSet="/art/056.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/056.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/057.webp" type="image/webp"/><source srcSet="/art/057.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/057.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/058.webp" type="image/webp"/><source srcSet="/art/058.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/058.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/059.webp" type="image/webp"/><source srcSet="/art/059.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/059.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/060.webp" type="image/webp"/><source srcSet="/art/060.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/060.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/061.webp" type="image/webp"/><source srcSet="/art/061.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/061.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/062.webp" type="image/webp"/><source srcSet="/art/062.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/062.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/063.webp" type="image/webp"/><source srcSet="/art/063.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/063.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/064.webp" type="image/webp"/><source srcSet="/art/064.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/064.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/065.webp" type="image/webp"/><source srcSet="/art/065.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/065.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/066.webp" type="image/webp"/><source srcSet="/art/066.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/066.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/067.webp" type="image/webp"/><source srcSet="/art/067.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/067.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/068.webp" type="image/webp"/><source srcSet="/art/068.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/068.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/069.webp" type="image/webp"/><source srcSet="/art/069.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/069.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/070.webp" type="image/webp"/><source srcSet="/art/070.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/070.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/071.webp" type="image/webp"/><source srcSet="/art/071.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/071.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/072.webp" type="image/webp"/><source srcSet="/art/072.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/072.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/073.webp" type="image/webp"/><source srcSet="/art/073.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/073.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/074.webp" type="image/webp"/><source srcSet="/art/074.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/074.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/075.webp" type="image/webp"/><source srcSet="/art/075.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/075.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/076.webp" type="image/webp"/><source srcSet="/art/076.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/076.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/077.webp" type="image/webp"/><source srcSet="/art/077.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/077.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/078.webp" type="image/webp"/><source srcSet="/art/078.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/078.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/079.webp" type="image/webp"/><source srcSet="/art/079.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/079.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/080.webp" type="image/webp"/><source srcSet="/art/080.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/080.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/081.webp" type="image/webp"/><source srcSet="/art/081.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/081.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/082.webp" type="image/webp"/><source srcSet="/art/082.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/082.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/083.webp" type="image/webp"/><source srcSet="/art/083.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/083.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/084.webp" type="image/webp"/><source srcSet="/art/084.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/084.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/085.webp" type="image/webp"/><source srcSet="/art/085.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/085.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/086.webp" type="image/webp"/><source srcSet="/art/086.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/086.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/087.webp" type="image/webp"/><source srcSet="/art/087.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/087.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/088.webp" type="image/webp"/><source srcSet="/art/088.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/088.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/089.webp" type="image/webp"/><source srcSet="/art/089.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/089.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/090.webp" type="image/webp"/><source srcSet="/art/090.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/090.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/091.webp" type="image/webp"/><source srcSet="/art/091.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/091.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/092.webp" type="image/webp"/><source srcSet="/art/092.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/092.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/093.webp" type="image/webp"/><source srcSet="/art/093.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/093.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/094.webp" type="image/webp"/><source srcSet="/art/094.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/094.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/095.webp" type="image/webp"/><source srcSet="/art/095.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/095.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/096.webp" type="image/webp"/><source srcSet="/art/096.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/096.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/097.webp" type="image/webp"/><source srcSet="/art/097.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/097.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/098.webp" type="image/webp"/><source srcSet="/art/098.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/098.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/099.webp" type="image/webp"/><source srcSet="/art/099.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/099.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/100.webp" type="image/webp"/><source srcSet="/art/100.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/100.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/101.webp" type="image/webp"/><source srcSet="/art/101.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/101.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/102.webp" type="image/webp"/><source srcSet="/art/102.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/102.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/103.webp" type="image/webp"/><source srcSet="/art/103.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/103.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/104.webp" type="image/webp"/><source srcSet="/art/104.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/104.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/105.webp" type="image/webp"/><source srcSet="/art/105.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/105.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/106.webp" type="image/webp"/><source srcSet="/art/106.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/106.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/107.webp" type="image/webp"/><source srcSet="/art/107.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/107.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"/></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/108.webp" type="image/webp"/><source srcSet="/art/108.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/108.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/109.webp" type="image/webp"/><source srcSet="/art/109.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/109.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                    <div className="art-column"><a className="gallery-link" href="">
                        <picture><source srcSet="/art/110.webp" type="image/webp"/><source srcSet="/art/110.jpg" type="image/jpeg"/><img  className="gallery-img" src="/art/110.webp" alt="art"/></picture>
                        <div className="art-overlay">
                            <div className="art-text"><p className="inner-art-text"></p></div>
                        </div>
                    </a></div>
                </div>
            </div>
            <div className="getintouch">
                <h2 className="getintouch-text">Get In Touch.</h2>
                <a href="mailto:djpearson27@gmail.com?subject=Message%20From%20davidpearsonart.com"><button className="sendmessage">Send Me A Message</button></a>
            </div>
        </div>
    </div>
    );
}

export default Art;